import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Welcome (I guess?)</h1>
    <p>Think it's quite clear this is a wip, but it might not look awful eventually</p>
    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
</div>*/}
  </Layout>
)

export default IndexPage
